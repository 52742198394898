.input {
    margin-bottom: 1rem;
    height: 40px;
    border: 0;
    border-radius: 0.5rem;
    background-color: var(--fontColor);
    color: var(--invertFontColor);
    padding: 1rem;
    border: 1px solid var(--primaryINColor);
}

.input::placeholder {
    color: --secondaryINColor;
}
