.header {
    background: #00000021;
    margin-bottom: 1.45rem;
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: 9999;
    width: 100%;
    min-width: 1027px; 
  }
  
  .headerContainer {
    margin: 0 auto; 
    padding: 1.45rem 1.0875rem;   
    a{
      display: flex;
      flex-direction: row;
    }

  }
  
  .headerContainer h1 {
    margin: 0;
  }
  
  .headerContainer a {
    color: #fff;
    text-decoration: none;
  }
  
  .containerImage {
    border-radius: 50%;
    background: #52823c;
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
    margin-right: 5px;
    text-align: center;
  }
  
  .containerTitles {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .title {
    text-shadow: 2px 3px 0 #6d6d6d;
  }
  .subTitle {
    font-size: 1rem;
    padding-left: 5px;
  }
  
  .imgCliente {
    margin: auto;
    font-size: 20px;
    top: 0;
    bottom: 0;
    display: inline-block;
    position: relative;
    height: 70px; 
    width: 70px; 
    border-radius: 50%;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  