.container {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    width: 100%;
    height: 100%;
}
.frame {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    width: 100%;
    height: 100%;
    padding-right: 0px;
    margin-right: 0px;

    iframe {
        position: relative;
        width: 100%;
        height: 100%;
        padding-right: 0px;
        margin-right: 0px;
        border: none;
    }
}

 
.loading {
    z-index: 888;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
}

