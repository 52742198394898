.headerContainer {
    height: 360px;
    box-shadow: 0px 5px 15px 7px rgba(0, 0, 0, 0.625);
    position: absolute;
    z-index: 9999;
    background-color: var(--primaryBGColor);
    width: 300px;
    border-radius: 8px;
}

.headerContent {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-items: center;
    margin: 0 0;
    padding: 5px 5px;
    height: 100%;
    border-radius: 8px;
    width: 100%;
}

.menuNav {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    background-color: var(--primaryBGColor);
    height: 100%;
    border-radius: 8px;
    width: 100%;
}

.contentDonload {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    margin-bottom: 6px;
    background-color: var(--secondaryBGColor);
    height: 100px;
    border-radius: 8px;
    margin-left: 2%;
    margin-right: 5px;
    width: 96%;
}

.contentUpload {
    display: flex;
    flex-direction: column;
    margin-top: 11px;
    margin-bottom: 6px;
    background-color: var(--secondaryBGColor);
    height: 100px;
    border-radius: 8px;
    margin-left: 2%;
    margin-right: 5px;
    width: 96%;
}

.mousePointer {
    cursor: pointer;
}

.contentBotton {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-bottom: 10px;
    height: 40px;
    border-radius: 8px;
    margin-left: 2%;
    margin-right: 5px;
    width: 96%;
    &:hover {
        filter: brightness(1.08);
    }
    span {
        color: var(--invertFontColor);
        font-weight: 600;
    }
    label {
        width: 100%;
        border-radius: 5px;
        height: 100%;
        color: var(--invertFontColor);
        font-size: 14px;
        display: flex;
        justify-content: center;
        gap: 20px;
        align-items: center;
        background-color: var(--primaryHLColor);
        cursor: pointer;
    }
}

.btnCallCenter{
    @extend .contentBotton; 
    margin-bottom: 2px;
    margin-top: 10px;

    span {
        color: var(--fontColor);
        font-weight: 500;
    }
    label{
        background-color: var(--primaryBGColor); 
        border: solid 1px var(--primaryINColor);
        gap: 10px;
       
    }

    .iconCallCenter{        
            font-size: 18px;
            color: var(--fontColor);  
    }
    &:hover{
        span {
            color: var(--primaryHLColor); 
        }  
        .iconCallCenter{ 
            color: var(--primaryHLColor); 
          }   
    }    
}

.btnLogout{
    @extend .contentBotton; 
}

.boxFiles {
    width: 94%;
    margin-left: 3%;
    border-radius: 5px;
    height: 36px;
    background-color: var(--primaryBGColor);
    border: 1px solid var(--primaryINColor);
    color: var(--fontColor);
    font-size: 14px;
    div[class~="control"] :hover {
        div[class~="placeholder"] {
            color: red;
        }
    }
}

.boxFilesOption {
    width: 100px;
}

.textDownFiles {
    color: var(--fontColor);
    font-size: 15px;
    margin-right: 6px;
}

.labelInputFile {
    margin-left: 0px;
    margin-top: 0px;
    cursor: pointer;
}

.labelUpload {
    width: 94%;
    margin-left: 3%;
    border-radius: 5px;
    height: 36px;
    background-color: var(--primaryBGColor);
    color: var(--fontColor);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid var(--primaryINColor);
    &:hover {
        color: var(--primaryHLColor);
    }
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconDownload {
    transition: transform 0.8s;
    &:hover {
        transform: scale(1.2);
    }
    margin-left: 45%;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.inputFile {
    display: none;
}

.versionControll { 
    font-size: small;
    position:absolute; 
    top: 0px;
    right: 5px;
    color: var(--secondaryINColor);
    margin-bottom: 0px;
    margin-right: 10px;
    margin-top: 5px;
}