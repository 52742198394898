.containerCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    align-items: center;
}

.login {
    display: flex;
    height: 320px;
    border-radius: 10px;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem 2rem;
    width: 500px;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.60);

    form {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    form button {
        height: 40px;
        font-size: 1.2rem;

        background-color: var(--primaryHLColor);
        a {
            color: var(--fontColor);
            font-weight: 600;
        }
    }
}

.modulo {
    color: var(--primaryHLColor);
    font-size: 24px;
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    text-align: center;
    width: 100%;
}
@media (max-width: 620px) {
    .login {
        width: 90%;
    }
}
