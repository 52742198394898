* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

:root {
    --fontColor: #fff;
    --invertFontColor: #000;

    --dark-900: #101026;
    --dark-700: #1d1d2e;

    --bgcolorgreen: #1f221e;
    --bgcolorgreen2: #353b32;

    --primaryBGColor: #202020;
    --secondaryBGColor: #3a3939;

    --primaryINColor: #8a8a8a;
    --secondaryINColor: #ffffffcc;

    --primaryHLColor: #3b853e;//#70b350;
    --secondaryHLColor: #f44336;
}

button {
    cursor: pointer;
}

a {
    color: inherit;
    text-decoration: none;
}

body {
    margin: 0;
    padding: 0;
    background: transparent;
    min-width: 1027px;
    width: 100%;
    min-height: 771px;
    height: 100%;
}

.App {
    min-width: 1027px;
    min-height: 771px;
    height: 100vh;
    width: 100%;
}

body,
input,
textarea,
select,
button {
    font: 400 1rem sans-serif;
}

@media (max-width: 720px) {
    html {
        font-size: 87.5%;
    }
}

@media (max-width: 1080px) {
    html {
        font-size: 93.75%;
    }
}

