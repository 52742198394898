.button {
    max-width: 600px;
    background-color: var(--primaryHLColor);
    border: 0;
    padding: 0.4rem;
    color: var(--fontColor);
    border-radius: 0.5rem;
    transition: filter 0.2s;

    &[disabled] {
        cursor: not-allowed;
        svg {
            animation: animate 2s infinite;
        }
    }

    &:hover {
        filter: brightness(1.08);
    }
}

.buttonText {
    color: var(--fontColor);
}

@keyframes animate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
