.headerContainer {
    display: flex;
    flex-direction: column;
    height: 45px;
    width: 45px;
    margin-left: calc(100% - 53px);
    top: calc(100% - 53px);
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.925);
    position: absolute;
    z-index: 9999;
    background-color: var(--primaryBGColor);
    border-radius: 50%;
}

.headerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    justify-items: center;
    margin: 0 auto;
    padding: 0 1rem;
    height: 100%;
    width: 40px;
}
.menuNav {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex-direction: column;
}

.boxFiles {
    width: 250px;
    border-radius: 5px;
    height: 26px;
    background-color: var(--primaryBGColor);
    color: var(--fontColor);
    font-size: 14px;
}

.textDownFiles {
    color: var(--fontColor);
    font-size: 15px;
    margin-right: 6px;
}

.labelInputFile {
    margin-left: 35px;
    margin-top: 4px;
    cursor: pointer;
}

.iconInputFile {
    transition: transform 0.8s;
    &:hover {
        transform: scale(1.2);
    }
}

.inputFile {
    display: none;
}

.btnRefresh {
    margin-left: 7px;
    margin-top: 4px;
    background: transparent;
    border: 0;
    transition: transform 0.8s;
    &:hover {
        transform: scale(1.2);
    }
}

.btnLogout {
    margin-left: 2rem;
    margin-top: 4px;
    background: transparent;
    border: 0;
    transition: transform 0.8s;
    &:hover {
        transform: scale(1.2);
    }
}

.btnMenu {
    margin-left: 1px;
    margin-top: 10px;
    background: transparent;
    border: 0;
    transition: transform 0.8s;
    &:hover {
        svg {
            color: var(--primaryHLColor);
        }
    }
}
